/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useMemo, useState } from 'react'
import _ from 'lodash'
import flow from 'lodash/fp/flow'
import getOr from 'lodash/fp/getOr';
import first from 'lodash/fp/first'
import URI from 'urijs'
import { useTranslation } from 'react-i18next'
import { useOrders, useSystemSettings } from 'react-omnitech-api'
import { useAlert, useLink, usePriceTemplate } from '../../hook'
import { isBrowser, trackEvent } from '../../helpers'
import CheckoutCompletedView from './checkout-completed-view'

function CheckoutCompletedController({
  location,
}) {
  // prepare hook
  const PRICE_TEMPLATE_KEY = _.get(usePriceTemplate(), 'code')
  const alert = useAlert()
  const { navigate } = useLink()
  const { t } = useTranslation()
  const { getSystemSetting } = useSystemSettings()

  // internal state
  const [order, setOrder] = useState({})
  const [pageReady, setPageReady] = useState(false)

  // local variable
  const seoTitle = t('screens.checkoutCompleted.seo.title')

  // useMemo
  const { fetchOrderByUuid } = useOrders()
  // get slug name from url params
  const { referenceNumber, uuid } = useMemo(() => {
    let search = {}
    if (isBrowser()) {
      const url = new URI(location.href)
      search = url.search(true)
    }
    return search
  }, [location])

  // get payment reference number for cybersource comment
  const paymentReferenceNumber = useMemo(() => {
    if (_.isEmpty(order)) return {}

    let result = ''
    // assume ecom only support single payment
    const paymentRequest = flow(
      getOr([], 'paymentRequests'),
      first,
    )(order)

    const paymentProvider = _.get(paymentRequest, 'paymentProvider', {})
    const {
      paymentProviderInternalCode,
      paymentGatewayServiceCode,
    } = paymentProvider
    switch (`${_.toUpper(paymentGatewayServiceCode)}___${paymentProviderInternalCode}`) {
      case 'CYBERSOURCE___CYBS_VM':
      case 'CYBERSOURCE___CYBS_VM_3DS':
      case 'CYBERSOURCE___CYBS_VM_TA':
      case 'CYBERSOURCE___CYBS_VM_TOKEN':
        result = paymentRequest.providerRemoteId
        break
      default:
        break
    }
    return result
  }, [order])

  async function fetchOrder() {
    try {
      const includes = [
        'order_line_properties',
        'order_lines.price_details',
        'order_lines.sku',
        'order_line_properties.price_details',
        'order_line_properties.sku',
        'price_details',
        'products.categories',
        'categories.department',
        'categories.parent',
        'payment_requests',
        'payment_requests.payment_provider',
        'shipments',
        'shipments.courier_service',
        'shipments.order_lines',
        'shipments.order_line_properties',
        'skus.color_option',
        'skus.product',
        'skus.size_option',
      ].join(',')
      const apiParams = {
        uuid,
        includes,
        schema_version: '2021-01-05',
        price_template: PRICE_TEMPLATE_KEY,
        arrayFormat: 'bracket',
      }
      const { order: data } = await fetchOrderByUuid(apiParams)
      setOrder(data)
      onTrackCheckoutCompleteOrder(data)
    } catch (error) {
      const generalError = _.get(error, 'generalError', {})
      alert.show(generalError.message, { state: 'error' })
    } finally {
      setPageReady(true)
    }
  }
  function onTrackCheckoutCompleteOrder(orderDetail) {
    const orderReferenceNumber = _.get(orderDetail, 'referenceNumber')
    const shipments = _.get(orderDetail, 'shipments')
    const totalPrice = _.get(orderDetail, 'priceDetails.orderTotalPrice')
    const delivery = _.get(orderDetail, 'priceDetails.orderDeliveryFee')
    const coupon = _.get(orderDetail, 'priceDetails.orderDiscount')
    const siteName = window.location.hostname
    // get from all shipments, all orderline in the same array, them in track event
    // we will sent addProduct for every sku in orderline
    const orderLineProperties = []
    _.map(shipments, (shipment) => {
      _.map(shipment.orderLineProperties, (line) => orderLineProperties.push(line))
    })
    const skus = _.map(orderLineProperties, (line) => line.sku)
    trackEvent('customerCheckoutCompleteOrder', {
      orderReferenceNumber,
      totalPrice,
      delivery,
      coupon,
      siteName,
      getSystemSetting,
    },
    {
      order: orderDetail,
      skus,
      orderItems: orderLineProperties,
      title: seoTitle,
    })
  }

  useEffect(() => {
    if (_.isEmpty(referenceNumber)) {
      navigate('/cart/')
    }
  }, [])

  // fetch order data and trigger tracking checkout completed
  useEffect(() => {
    if (_.isEmpty(uuid)) return

    fetchOrder()
  }, [])

  const viewProps = {
    order,
    pageReady,
    paymentReferenceNumber,
    seoTitle,
  }

  return (
    <CheckoutCompletedView {...viewProps} />
  )
}

export default CheckoutCompletedController
